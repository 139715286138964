import {gql} from 'apollo-boost'

const fragments = `
{
    id
    name
    slug
    categories{
        id
        name
    }
    createdAt
}
`
console.log("34222222222222224234"+JSON.stringify(fragments))
export const create = gql`
    mutation createProductType($data: ProductTypeCreateInput!){
        createProductType(data: $data)${fragments}
    }
`

export const get = gql`
    query productTypes($where: ProductTypeWhereInput ){
        productTypes(where: $where)${fragments}
    }
`

export const update = gql`
    mutation updateProductType(
        $data: ProductTypeUpdateInput!, 
        $where: ProductTypeWhereUniqueInput!
    ){
        updateProductType(data: $data, where: $where)${fragments}
    }
`

export const remove = gql`
    mutation deleteProductType($where: ProductTypeWhereUniqueInput!){
        deleteProductType(where: $where)${fragments}
    }
`
